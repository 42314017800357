var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "MatterStatus" },
    _vm._l(_vm.matterObj, function (item, key) {
      return _c("div", { key: key }, [
        item.length
          ? _c(
              "div",
              { staticClass: "matter" },
              [
                _c("div", { staticClass: "matter-title flex-align-center" }, [
                  key === "toBeList"
                    ? _c("svg", { staticClass: "iconpark-icon" }, [
                        _c("use", { attrs: { href: "#daiban" } }),
                      ])
                    : _c("svg", { staticClass: "iconpark-icon" }, [
                        _c("use", { attrs: { href: "#genjin" } }),
                      ]),
                  _c("span", [
                    _vm._v(_vm._s(key === "toBeList" ? "待办" : "跟进")),
                  ]),
                ]),
                _vm._l(item, function (status, i) {
                  return _c(
                    "div",
                    {
                      key: i,
                      staticClass: "matter-content flex-align-center",
                      on: {
                        click: function ($event) {
                          return _vm.jumpDetail(status)
                        },
                      },
                    },
                    [
                      _c("span", { staticClass: "status-title" }, [
                        _vm._v(_vm._s(status.statusTitle)),
                        _c("span", { staticClass: "user-num" }, [
                          _vm._v(_vm._s(status.userList.length)),
                        ]),
                      ]),
                      _vm._l(status.userList, function (user, userIndex) {
                        return _c("div", { key: "" + userIndex }, [
                          userIndex < _vm.limitNum
                            ? _c(
                                "span",
                                { staticClass: "user-box flex-align-center" },
                                [
                                  _c("CbAvater", {
                                    attrs: {
                                      "on-job-id": user.onJobId,
                                      "avater-name": user.staffName,
                                      size: "26",
                                      "default-name-length": 1,
                                      "show-user-name": false,
                                    },
                                  }),
                                  user.staffName && user.staffName.length < 4
                                    ? _c("span", { staticClass: "user-name" }, [
                                        _vm._v(_vm._s(user.staffName)),
                                      ])
                                    : _c(
                                        "a-tooltip",
                                        { staticClass: "user-name" },
                                        [
                                          _c("template", { slot: "title" }, [
                                            _vm._v(
                                              " " + _vm._s(user.staffName) + " "
                                            ),
                                          ]),
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm._f("ellipsis")(
                                                  user.staffName
                                                )
                                              ) +
                                              "... "
                                          ),
                                        ],
                                        2
                                      ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ])
                      }),
                      status.userList.length > _vm.limitNum
                        ? _c("div", { staticClass: "hide-num flex-center" }, [
                            _vm._v(
                              "+" +
                                _vm._s(
                                  status.userList.length - _vm.limitNum > 99
                                    ? 99
                                    : status.userList.length - _vm.limitNum
                                )
                            ),
                          ])
                        : _vm._e(),
                    ],
                    2
                  )
                }),
              ],
              2
            )
          : _vm._e(),
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }